<template>
  <div>
    <headers />
    <div class="content">
      <div class="bg">
        <img class="h1" :src="require('@/assets/image/h5/partner/h1.png')" alt="" />
        <img class="h2" :src="require('@/assets/image/h5/partner/h2.png')" alt="" />
        <div class="line1"></div>
        <div class="if">
          <div class="text1">
            <div>如果你:</div>
            <div class="con">· 正在寻找快实施、稳利润的产品<br />· 正在寻找低投入、高回报项目<br />· 想要加入数字化转型浪潮</div>
          </div>
          <div class="text2">欢迎你加入 “数字厂长” 城市合伙人</div>
        </div>
        <div class="line1 blue"></div>
        <div class="titleHed hen1">
          <div class="titleline"></div>
          <div class="titlename">这里有</div>
          <div class="titleline"></div>
        </div>
        <div class="therehas">
          <div class="box">
            <div class="imgBox">
              <img class="img1" :src="require('@/assets/image/h5/partner/img1.png')" alt="" />
            </div>
            <div class="tag">现实红利</div>
            <div class="text">数百万利润回报</div>
          </div>
          <div class="box">
            <div class="imgBox">
              <img class="img2" :src="require('@/assets/image/h5/partner/img2.png')" alt="" />
            </div>
            <div class="tag">一天上线</div>
            <div class="text">快速应用的可推广<br />可复制的制造业数<br />字化平台</div>
          </div>
          <div class="box">
            <div class="imgBox">
              <img class="img3" :src="require('@/assets/image/h5/partner/img3.png')" alt="" />
            </div>
            <div class="tag">携手共赢</div>
            <div class="text">在数转浪潮中共享红利</div>
          </div>
        </div>
        <div class="titleHed hen2">
          <div class="titleline"></div>
          <div class="titlename">什么产品?</div>
          <div class="titleline"></div>
        </div>
        <div class="shuzi">“数字厂长”——中小型制造企业数字化转型最佳选择。</div>
        <div class="whatprod">
          <div class="box">
            <div class="imgBox">
              <img class="img4" :src="require('@/assets/image/h5/partner/img4.png')" alt="" />
            </div>
            <div class="tag">懂</div>
            <div class="text">更懂制造业的数字化转型，千家企业现场实践，通用性的SAAS平台。</div>
          </div>
          <div class="box">
            <div class="imgBox">
              <img class="img5" :src="require('@/assets/image/h5/partner/img5.png')" alt="" />
            </div>
            <div class="tag">视</div>
            <div class="text">全程可视化，全程知晓工厂运<br />作，软硬件结合全面显示。</div>
          </div>
          <div class="box">
            <div class="imgBox">
              <img class="img6" :src="require('@/assets/image/h5/partner/img6.png')" alt="" />
            </div>
            <div class="tag">通</div>
            <div class="text">供、产、销全面打通，达成<br />厂内物料守恒，信息共通。</div>
          </div>
          <div class="box">
            <div class="imgBox">
              <img class="img7" :src="require('@/assets/image/h5/partner/img7.png')" alt="" />
            </div>
            <div class="tag">快</div>
            <div class="text">一天平台上线，十分钟操作上手，<br />半月全线理顺，快速数字化实施.</div>
          </div>
        </div>
        <div class="titleHed hen2 moreline">
          <div class="titleline" style="width: 16%;"></div>
          <div class="titlename">对数字化不熟悉？对市场没信心？<br />没关系，四大帮助全力助您成功</div>
          <div class="titleline" style="width: 16%;"></div>
        </div>
        <div class="helpyou">
          <div class="box">
            <div class="imgBox">
              <img class="img8" :src="require('@/assets/image/h5/partner/img8.png')" alt="" />
            </div>
            <div class="tag">全面培训</div>
            <div class="text">专职人员全面培训，完<br />整课程助您快速上手</div>
          </div>
          <div class="box">
            <div class="imgBox">
              <img class="img9" :src="require('@/assets/image/h5/partner/img9.png')" alt="" />
            </div>
            <div class="tag">技术支持</div>
            <div class="text">专家人员现场支持，实<br />施完全承担无需操心</div>
          </div>
          <div class="box">
            <div class="imgBox">
              <img class="img10" :src="require('@/assets/image/h5/partner/img10.png')" alt="" />
            </div>
            <div class="tag">人员助力</div>
            <div class="text">专业人员全面助力,<br />会销面销辅助支撑。</div>
          </div>
          <div class="box">
            <div class="imgBox">
              <img class="img11" :src="require('@/assets/image/h5/partner/img11.png')" alt="" />
            </div>
            <div class="tag">辅助获利</div>
            <div class="text">合伙人提供渠道，公司销<br />售人员上阵让您坐享提成</div>
          </div>
        </div>
        <div class="titleHed hen2">
          <div class="titleline"></div>
          <div class="titlename">适用行业</div>
          <div class="titleline"></div>
        </div>
        <div class="shuzi">“数字厂长”广泛适用多种制造业，包括但不限于：</div>
        <div class="tagbox">
          <div class="tag">机械设备</div>
          <div class="tag">轨道交通</div>
          <div class="tag">汽车制造</div>
          <div class="tag">专用设备</div>
          <div class="tag">铸造</div>
          <div class="tag">半导体</div>
          <div class="tag">注塑冲压</div>
          <div class="tag">泵</div>
          <div class="tag">阀门机械制造</div>
          <div class="tag">医疗器械</div>
          <div class="tag">智能制造</div>
          <div class="tag">非金属矿物制品</div>
          <div class="tag">电气机械和器材</div>
          <div class="tag">传感设备</div>
          <div class="tag">电器设备</div>
          <div class="tag">冶金</div>
          <div class="tag">集成电路</div>
          <div class="tag">高分子材料</div>
          <div class="tag">机加工和模具</div>
          <div class="tag">通用航空</div>
          <div class="tag">金属材料</div>
          <div class="tag">轴承</div>
          <div class="tag">齿轮制造</div>
          <div class="tag">通用零部件制造</div>
          <div class="tag">仪器仪表</div>
          <div class="tag">家具制造</div>
        </div>
        <div class="titleHed hen2">
          <div class="titleline"></div>
          <div class="titlename">申请条件</div>
          <div class="titleline"></div>
        </div>
        <div class="shuzi">如果您:</div>
        <div class="stepBox">
          <div class="stepline"></div>
          <div class="step">
            <div class="num">01</div>
            <div class="steptext">是在上述细分行业中的某一个或多个行业内有丰富的企业人脉资源的个人或团队；</div>
          </div>
          <div class="step">
            <div class="num">02</div>
            <div class="steptext">或者在本区域内上述行业的行业协会任会长、副会长、理事长或者秘书长等职位；</div>
          </div>
          <div class="step">
            <div class="num">03</div>
            <div class="steptext">或者您在上述细分行业有大量的下游企业客户资源；</div>
          </div>
          <div class="welcome">那么欢迎您加入“数字厂长”城市合伙人的队伍，共享制造业中小企业数字化转型的红利。</div>
        </div>
        <div class="titleHed hen2">
          <div class="titleline"></div>
          <div class="titlename">城市合伙人的合作流程</div>
          <div class="titleline"></div>
        </div>
        <div class="stepBox">
          <div class="stepline stepline2"></div>
          <div class="step">
            <div class="num">01</div>
            <div class="steptext">城市合伙人完成报名信息表的填写；</div>
          </div>
          <div class="step">
            <div class="num">02</div>
            <div class="steptext">经筛选后，完成沟通，并签署城市合伙人合作协议；</div>
          </div>
          <div class="step">
            <div class="num">03</div>
            <div class="steptext">我们对城市合伙人进行集中培训；</div>
          </div>
          <div class="step">
            <div class="num">04</div>
            <div class="steptext">城市合伙人开展业务。</div>
          </div>
        </div>
        <div class="titleHed hen2">
          <div class="titleline"></div>
          <div class="titlename">城市合伙人合作方式</div>
          <div class="titleline"></div>
        </div>
        <div class="stepBox">
          <div class="stepline stepline2"></div>
          <div class="step">
            <div class="num">01</div>
            <div class="steptext">信息渠道合伙人：提供有效客户信息，并保证畅通信息渠道，帮助完成订单，收取销售佣金</div>
          </div>
          <div class="step">
            <div class="num">02</div>
            <div class="steptext">商业战略合伙人：自主完成销售，独立完成合同签订，收取商业利润。</div>
          </div>
          <div class="step">
            <div class="num">03</div>
            <div class="steptext">全面战略合伙人：自主完成销售、实施工作，独立经营区域内销售、实施、售后的整体工作，形成战略合作，获得整体利润。</div>
          </div>
        </div>
      </div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/H5/hend';
import footers from '@/components/H5/foot';
export default {
  components: {
    headers,
    footers,
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  min-width: 320px;
  max-width: 100vw;
  margin: 0 auto;
  overflow-x: hidden;
  font-family: "Helvetica Neue", Helvetica, STHeiTi, sans-serif;
  font-size: 14px;
  line-height: 1.6;

  .bg {
    width: 100%;
    height: 2378px;
    background: url('~@/assets/image/h5/partner/bg.png') center no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
  }

  .h1 {
    margin: 31px auto 0;
    width: 184px;
    height: 19px;
    display: block;
  }

  .h2 {
    margin: 15px auto 0;
    width: 335px;
    height: 30px;
    display: block;
  }

  .line1 {
    position: relative;
    margin: 22px auto 0;
    width: 325px;
    height: 1px;
    border-top: 1px solid;
    border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;

    &:after {
      position: absolute;
      top: -2.6px;
      right: 99px;
      content: '';
      width: 5px;
      height: 5px;
      background: #FFFFFF;
      border-radius: 50%;
    }

    &.blue {
      &::after {
        background: #9EFCFF;
        left: 99px;
      }
    }
  }

  .if {
    margin: 12px auto 0;
    width: 250px;

    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: rgba(255, 255, 255, 0.8);
    line-height: 26px;

    .text1 {
      display: flex;

      .con {
        margin-left: 20px;
      }
    }
  }

  .titleHed {
    &.hen1 {
      margin-top: 35px;
    }

    &.hen2 {
      margin-top: 30px;
    }

    position: relative;
    margin: auto;
    width: 345px;
    display: flex;
    align-items: center;

    &::before {
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      background: #FFFFFF;
      border-radius: 50%;
      left: 0;
      top: 6px;
    }

    &::after {
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      background: #FFFFFF;
      border-radius: 50%;
      right: 0;
      top: 6px;
    }

    &.moreline {
      &::before {
        top: 14px;
      }

      &::after {
        top: 14px;
      }
    }

    .titleline {
      width: 50%;
      height: 1px;
      background: #FFFFFF;
    }

    .titlename {
      margin: 0 26px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #FFFFFF;
      line-height: 16px;
      word-break: keep-all;
    }
  }

  .therehas {
    margin: 20px auto 0;
    width: 345px;
    display: flex;
    justify-content: space-between;

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 110px;
      height: 126px;
      border-radius: 15px;
      border: 1px solid #9EFCFF;
    }

    .imgBox {
      margin-top: 5px;
      height: 39px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .img1 {
      width: 25px;
      height: 29px;
    }

    .img2 {
      width: 27px;
      height: 25px;
    }

    .img3 {
      width: 26px;
      height: 27px;
    }

    .tag {
      margin-top: 5px;
      padding: 0 12px;
      height: 22px;
      line-height: 22px;
      border-radius: 15px;
      border: 1px solid #9DFBFF;
      font-size: 12px;
      color: #FFFFFF;
      background: #004EA7;
    }

    .text {
      margin-top: 5px;
      font-size: 10px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 13px;
      text-align: center;
    }
  }

  .shuzi {
    margin-top: 10px;
    text-align: center;
    font-size: 10px;
    font-family: MicrosoftYaHei;
    color: rgba(255, 255, 255, 0.85);
    line-height: 13px;
  }

  .whatprod {
    margin: 10px auto 0;
    width: 345px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .box {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      align-items: center;
      width: 167px;
      height: 131px;
      border-radius: 15px;
      border: 1px solid #9EFCFF;
    }

    .imgBox {
      margin-top: 10px;
      height: 40px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .img4 {
      width: 35px;
      height: 35px;
    }

    .img5 {
      width: 28px;
      height: 28px;
    }

    .img6 {
      width: 27px;
      height: 27px;
    }

    .img7 {
      width: 28px;
      height: 28px;
    }

    .tag {
      margin-top: 5px;
      padding: 0 19px;
      height: 20px;
      line-height: 20px;
      border-radius: 15px;
      border: 1px solid #9DFBFF;
      font-size: 12px;
      color: #FFFFFF;
      background: #004EA7;
    }

    .text {
      margin-top: 10px;
      font-size: 10px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 13px;
      text-align: center;
    }
  }

  .helpyou {
    margin: 10px auto 0;
    width: 345px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      width: 167px;
      height: 132px;
      border-radius: 15px;
      border: 1px solid #9EFCFF;
    }

    .imgBox {
      margin-top: 10px;
      height: 40px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .img8 {
      width: 30px;
      height: 30px;
    }

    .img9 {
      width: 27px;
      height: 24px;
    }

    .img10 {
      width: 24px;
      height: 24px;
    }

    .img11 {
      width: 30px;
      height: 30px;
    }

    .tag {
      margin-top: 5px;
      padding: 0 12px;
      height: 20px;
      line-height: 20px;
      border-radius: 15px;
      border: 1px solid #9DFBFF;
      font-size: 12px;
      color: #FFFFFF;
      background: #004EA7;
    }

    .text {
      margin-top: 10px;
      font-size: 10px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 13px;
      text-align: center;
    }
  }

  .tagbox {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    margin: 20px auto 0;
    width: 345px;
    border-radius: 15px;
    border: 2px solid #9EFCFF;
    box-sizing: border-box;

    .tag {
      margin: 5px;
      padding: 0 11px;
      height: 20px;
      line-height: 20px;
      border-radius: 15px;
      border: 1px solid #9DFBFF;
      font-size: 12px;
      color: #FFFFFF;
      background: #004EA7;
    }
  }

  .stepBox {
    margin: 30px auto 0;
    width: 345px;
    position: relative;

    .stepline {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 16px;
      width: 1px;
      height: 165px;
      border-left: 1px solid;
      border-image: linear-gradient(180deg, rgba(204, 253, 255, 0), rgba(204, 253, 255, 1), rgba(177, 252, 255, 1), rgba(157, 251, 255, 0)) 1 1;

      &.stepline2 {
        height: 145px;
      }
    }

    .step {
      position: relative;
      z-index: 2;
      margin-top: 20px;
      display: flex;
      align-items: center;

      &:first-child {
        margin-top: 0;
      }

      .num {
        width: 30px;
        height: 20px;
        border-radius: 15px;
        border: 1px solid #9DFBFF;
        text-align: center;
        font-size: 10px;
        color: #FFFFFF;
        background: #004EA7;
      }

      .steptext {
        margin-left: 10px;
        width: calc(100% - 40px);
        color: #FFFFFF;
        line-height: 16px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
      }
    }
  }

  .welcome {
    margin-top: 40px;
    padding: 0 15px;
    width: 345px;
    height: 40px;
    line-height: 20px;
    border-radius: 20px;
    border: 1px solid #9EFCFF;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.85);
    box-sizing: border-box;

  }
}
</style>
